<template>
  <div class="row  bg-light shadow-sm py-2 mb-4" v-cloak xmlns:v-on="http://www.w3.org/1999/xhtml"
       xmlns:v-bind="http://www.w3.org/1999/xhtml">
    <transition name="fade" mode="out-in">
      <new-member v-if="register"
                  :programs="programs"
                  :token="token"
                  class="col col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto py-2"
                  @login="updateLogin"
                  :input-hidden-data="registerHiddenData"
                  :action="registerAction"
                  key="register"/>
      <login-user v-else
                  @register="register = true"
                  :token="token"
                  :initial-username="username"
                  :initial-password="password"
                  :input-hidden-data="loginHiddenData"
                  :action="loginAction"
                  :show-remember-me="showRememberMe"
                  class="col col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto py-2"
                  key="login"/>
    </transition>
  </div>
</template>

<script>
import { createElementFromHTML, isNotEmpty } from '@/utilities/utilities'

export default {
  name: 'LoginPortal',
  components: {
    LoginUser: () => import(/* webpackChunkName: "Login" */ '@/components/members/Login'),
    NewMember: () => import(/* webpackChunkName: "NewMember" */ '@/components/members/NewMember')
  },
  props: {
    loggedIn: {
      type: Boolean,
      required: true,
      default: false
    },
    programs: {
      type: Array,
      required: false,
      default: () => { return [] }
    },
    token: {
      type: [Boolean, String],
      required: true
    },
    loginForm: {
      type: String,
      required: false
    },
    registerForm: {
      type: String,
      required: false
    },
    showRememberMe: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      register: false,
      username: '',
      password: ''
    }
  },
  created () {

  },
  computed: {
    loginAction () {
      const form = createElementFromHTML(this.loginForm)
      return form.action
    },
    loginHiddenData () {
      const form = createElementFromHTML(this.loginForm)
      const inputs = Array.from(form.getElementsByTagName('input'))
      return inputs.map(input => {
        return {
          type: input.type,
          name: input.name,
          value: input.value
        }
      }).filter(map => {
        return map.type === 'hidden'
      })
    },
    registerAction () {
      const form = createElementFromHTML(this.registerForm)
      return form.action
    },
    registerHiddenData () {
      const form = createElementFromHTML(this.registerForm)
      const inputs = Array.from(form.getElementsByTagName('input'))
      return inputs.map(input => {
        return {
          type: input.type,
          name: input.name,
          value: input.value
        }
      }).filter(map => {
        return map.type === 'hidden'
      })
    }
  },
  methods: {
    updateLogin (member) {
      this.register = false
      if (isNotEmpty(member) && isNotEmpty(member.username)) {
        this.username = member.username
      }
      if (isNotEmpty(member) && isNotEmpty(member.password)) {
        this.password = member.password
      }
    }
  }
}
</script>

<style scoped>

</style>
